<template>
    <div class="">
        <searchBar @search="search"></searchBar>
        <div class="table bgf">
            <detail-table ref="detailTable"></detail-table>
            <div class="bgf utils">
                <div class="flex_cen mt_15">
                    <el-button type="primary" class="submit mr_20 fs_16" @click="ruleForms">下一步</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import detailTable from './components/detail-table'
import { s_getItem, s_removeItem } from "@/common/cache"
export default {
	name: 'TaxesCheck',
	components: { detailTable },
	data() {
		return {
			id: ''
		}
	},
	created() {
		this.id = this.$route.params.id
	},
	mounted() {
		this.$refs.detailTable.tableData = s_getItem('tableData') || []
		let amount = s_getItem('amount') || {}
		amount.synthesis_fee = Number(amount.service_fee)*100 + Number(amount.tax_fee)*100
		amount.synthesis_fee = (amount.synthesis_fee/100).toFixed(2)
		this.$refs.detailTable.amount = amount
	},
	beforeRouteLeave (to, from, next) {
		if(to.name != 'TaxesOrder') {
			s_removeItem('tableData')
			s_removeItem('amount')
		}
		next()
	},
	methods: {
		ruleForms() {
			this.$router.push(`/payTaxes/order/${this.id}`)
		}
	}
}
</script>

<style lang='less' scoped>
.table {
    padding: 32px 40px;
}

.utils {
    padding-bottom: 30px;
    margin-top: 30px;
    .sun-price {
        border-top: 1px solid #e7ecf6;
        border-bottom: 1px solid #e7ecf6;
        padding: 20px 40px;
    }
    .mr_30 {
        margin-right: 30px;
    }
    .submit {
        width: 200px;
        line-height: 24px;
        margin-top: 40px;
    }
}
</style>